import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';

export default function Alert(props: {
    id?: string;
    title: string;
    content: string;
    open: boolean;
    handleOpen: any;
    handleClose: any;
}) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby={`${props.id}-alert-dialog-title`}
            aria-describedby={`${props.id}-alert-dialog-description`}>
            <div className="popup-wrap">
                <div className="popup-heading">
                    <p id={`${props.id}-alert-dialog-title`}>{props.title}</p>
                </div>
                <p className="popup-body" id={`${props.id}-alert-dialog-description`}>
                    {props.content}
                </p>
                <button className="popup-btn" onClick={props.handleClose} autoFocus>
                    Ok
                </button>
            </div>
        </Dialog>
    );
}
