import React, { ChangeEvent, useState, useEffect, useRef } from 'react';

import '../Style/App.scss';
import '../Style/Responsive.scss';
import '../Style/Home.scss';

import notoficationIcon from '../Assets/notoficationIcon.svg';
import notoficationHaveIcon from '../Assets/notoficationHaveIcon.svg';
import propic from '../Assets/defaultUser.jpg';

import { isDID, VIEWS } from '../utils';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    setHolderDID,
    setHolderPrivateKey,
    setImage,
    setIsIdentityCreated,
    setIssuerDID,
    setIssuerPrivateKey,
    setMnemonic,
    setName,
    _image,
    _receivingPrivateKey,
    _enrolledActivity,
    setEnrolledActivity
} from '../redux/identitySlice';

import Spinner from '../components/Spinner';
import Alert from '../components/Alert';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import socket from '../socket';
import logo from '../Assets/logo.svg';
import moment from 'moment';
import createVP from '../utils/create-vp';
import clockIcon from '../Assets/pending-clock.svg';
import infoIcon from '../Assets/info.svg';
import educationLogo from '../Assets/education-logo.svg';
import LOCAL_STORAGE from '../utils/local-storage';
import indexedDB from '../utils/storage';

let useClickOutside = (handler: any) => {
    let dropdownMenuRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        let maybeHandler = (event: any) => {
            if (!dropdownMenuRef?.current?.contains(event.target)) {
                handler();
            }
        };
        document.addEventListener('mousedown', maybeHandler);
        return () => {
            document.removeEventListener('mousedown', maybeHandler);
        };
    });
    return dropdownMenuRef;
};

export default function Home() {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [acceptedRewards, setAcceptedRewards] = useState([]);
    const [unAcceptedRewards, setUnAcceptedRewards] = useState([]);
    const [isInput, setisInput] = useState(true);
    const [counts, setCounts] = useState({ accept: 0, rewards: 0 });
    const [validationTxt, setValidationTxt] = useState();
    const [verificationURL, setVerificationURL] = useState('');
    const profileImage = useAppSelector(_image);
    const [notificationIn, setNotificationIn] = useState(false);
    const [rewardsLoading, setRewardsLoading] = useState(false);
    const [pendingRewards, setPendingRewards] = useState([]);

    const userToken = sessionStorage.getItem('userToken');
    const decodedUserToken: any = jwt.decode(userToken as string);
    const uid = decodedUserToken.receivingDID;
    const enrolledActivity = useAppSelector(_enrolledActivity);
    const holderPrivateKey = useAppSelector(_receivingPrivateKey);

    const socketHandler = (data: any) => {
        //console.log('NEW DATA RECIEVED HOME', data)
        if (data) {
            getAllRewards();
        }
    };

    useEffect(() => {
        getAllRewards();

        socket.on(uid || '', socketHandler);
        return () => {
            socket.off(uid, socketHandler);
        };
    }, [uid]);

    const getAllRewards = async () => {
        setRewardsLoading(true);

        getRewardsCount(uid)
            .then((res) => {
                if (res.data?.count) setCounts((prev) => ({ ...prev, rewards: res.data.count }));
            })
            .catch((err) => {});

        getRewards(uid)
            .then((res) => {
                if (res.data[0].unread.length !== 0) {
                    setNotificationIn(true);
                }
            })
            .catch((err) => {});

        Promise.all([
            getAcceptedRewards(uid).then((res) => {
                //console.log(res.data);
                setAcceptedRewards(res.data.accepted_credentials || []);
            }),
            getUnAcceptedRewards(uid).then((res) => {
                //console.log(res.data);
                setUnAcceptedRewards(res.data.credentials || []);
            }),
            getPendingRewards(uid).then((res) => {
                setPendingRewards(res.data?.pending_rewards || []);
            })
        ]).finally(() => {
            setRewardsLoading(false);
        });
    };

    const getRewards = async (uid: string) => {
        const rewards = await axios.get(
            `${process.env.REACT_APP_REWARD_BACKEND}notifications/getUnreadNotifications`,
            {
                params: {
                    uid
                }
            }
        );

        return rewards;
    };

    const getPendingRewards = async (did: string) => {
        try {
            const rewards = await axios.get(
                `${process.env.REACT_APP_REWARD_BACKEND}reward/pendingRewards`,
                {
                    params: {
                        did
                    }
                }
            );

            const request = indexedDB.init('RewardsDatabase');
            indexedDB.upgrade(request, 'pending_rewards');
            indexedDB.put(request, 'pending_rewards', rewards?.data, did);

            return rewards;
        } catch (error) {
            const request = indexedDB.init('RewardsDatabase');
            indexedDB.upgrade(request, 'pending_rewards');
            const result = await indexedDB.get(request, 'pending_rewards', did);

            if (result?.data) return { data: result.data };
        }
    };

    const getRewardsCount = async (did: string) => {
        try {
            const rewardsCount = await axios.get(
                `${process.env.REACT_APP_REWARD_BACKEND}reward/getRewardPresentationsCount`,
                {
                    params: {
                        did
                    }
                }
            );

            const request = indexedDB.init('RewardsDatabase');
            indexedDB.upgrade(request, 'rewards_count');
            indexedDB.put(request, 'rewards_count', rewardsCount?.data, did);

            return rewardsCount;
        } catch (error) {
            const request = indexedDB.init('RewardsDatabase');
            indexedDB.upgrade(request, 'rewards_count');
            const result = await indexedDB.get(request, 'rewards_count', did);

            if (result?.data) return { data: result.data };
        }
    };

    const getUnAcceptedRewards = async (did: string) => {
        const rewards = await axios.get(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/getUnacceptedRewardCredentials`,
            {
                params: {
                    did
                }
            }
        );

        return rewards;
    };

    const getAcceptedRewards = async (did: string) => {
        try {
            const rewards = await axios.get(
                `${process.env.REACT_APP_REWARD_BACKEND}reward/getAcceptedRewardCredentials`,
                {
                    params: {
                        did
                    }
                }
            );

            const request = indexedDB.init('RewardsDatabase');
            indexedDB.upgrade(request, 'accepted_rewards');
            indexedDB.put(request, 'accepted_rewards', rewards?.data, did);

            return rewards;
        } catch (error) {
            const request = indexedDB.init('RewardsDatabase');
            indexedDB.upgrade(request, 'accepted_rewards');
            const result = await indexedDB.get(request, 'accepted_rewards', did);

            if (result?.data) return { data: result.data };
        }
    };

    function handleLogout() {
        dispatch(setMnemonic(''));
        dispatch(setName(''));
        dispatch(setImage(''));
        dispatch(setHolderPrivateKey(''));
        dispatch(setHolderDID(''));
        dispatch(setIssuerPrivateKey(''));
        dispatch(setIssuerDID(''));
        dispatch(setIsIdentityCreated(false));
        sessionStorage.removeItem('userToken');
    }

    function goToMyProfile() {
        history.push(VIEWS.PROFILE);
    }

    let dropdownMenuRef = useClickOutside(() => {
        setIsOpen(false);
    });

    function formatDate(date: string) {
        return moment(date).format('YYYY/MMM/DD');
    }

    const acceptRewards = async (
        did: string,
        id: string,
        vp: any,
        rewardId: string,
        vcsData: any
    ) => {
        const rewardsRes = await axios.put(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/acceptRewardCredential`,
            {
                did,
                id,
                vp,
                rewardId,
                vcsData
            }
        );

        return rewardsRes;
    };

    async function acceptRewardFunc(reward: any) {
        setIsLoading(true);
        const vcs = reward.elements.map((item: any) => item.vc);
        const vcsData = reward.elements.map((item: any) => {
            return {
                id: item?._id,
                hide: false,
                issuerLinkedin: item?.issuerLinkedin
            };
        });

        if (vcs.length === 0) {
            return setAlertOpen({
                title: 'Error',
                content: 'At least one reward is required to accept the reward',
                open: true
            });
        }
        const vp: any = await createVP({ did: uid, vcs: vcs, private_key: holderPrivateKey });

        if (vp?.error) {
            return setAlertOpen({
                title: 'Error',
                content: vp?.error,
                open: true
            });
        }

        await removeNotification(uid, reward?.reward);

        acceptRewards(uid, reward?.reward || '', vp, reward?.reward, vcsData)
            .then((res) => {
                setIsLoading(false);

                getAllRewards();
                setAlertOpen({
                    title: 'Success',
                    content:
                        'You have successfully signed and accepted the reward, visit My rewards section to view your rewards',
                    open: true
                });
            })
            .catch((e) => {
                setIsLoading(false);
            });
    }

    const cancelReward = async (did: string, id: string) => {
        removeNotification(did, id);

        const rewards = await axios.delete(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/declineRewardCredential`,
            {
                data: {
                    did,
                    id
                }
            }
        );
        return rewards;
    };

    function declineRewardFunc(reward) {
        setIsLoading(true);
        cancelReward(uid, reward?.reward || '')
            .then((res) => {
                setIsLoading(false);
                getAllRewards();
            })
            .catch((e) => {
                setIsLoading(false);
            });
    }

    const onClickReward = (id: string) => {
        history.push(VIEWS.MYREWARDPREVIEW + '?id=' + id);
    };

    function onReceivedRewardClick(id: string) {
        history.push(VIEWS.RECEIVEDREWARDPREVIEW + '?id=' + id);
    }

    const removeNotification = async (uid: string, id: string) => {
        const contact = await axios.delete(
            `${process.env.REACT_APP_REWARD_BACKEND}notifications/removeNotification`,
            {
                data: {
                    uid,
                    id
                }
            }
        );

        return contact;
    };

    React.useEffect(() => {
        if (enrolledActivity) {
            setAlertOpen({
                title: 'Success',
                content: `Hello. You have successfully enrolled in the “${enrolledActivity}” and the certificate has not been issued yet.`,
                open: true
            });

            dispatch(setEnrolledActivity(''));
        }
    }, [enrolledActivity]);

    React.useEffect(() => {
        (async () => {
            /* const request = indexedDB.init('RewardsDatabase');

            indexedDB.upgrade(request, 'accepted_rewards');
            indexedDB.put(request, 'accepted_rewards', { hi: 'message' });
            const result = await indexedDB.get(request, 'accepted_rewards', 1);
            console.log(result); */
        })();
    }, []);

    return (
        <React.Fragment>
            <div className="home-container container container-back container-h">
                <div className="top-navbar">
                    <div className="nav_left">
                        <img src={logo} alt="Micro Rewards Logo" className="logo-image" />
                    </div>
                    <div className="nav_right">
                        <ul>
                            <div ref={dropdownMenuRef}>
                                <li className="nr_li profile-dropdown-main">
                                    <button
                                        onClick={() => setIsOpen((isOpen) => !isOpen)}
                                        className="btn-without-background">
                                        <img className="avatar" src={profileImage || propic}></img>
                                    </button>
                                    <div
                                        className={`profile-dropdown-menu animate__animated animate__fadeIn ${
                                            isOpen ? 'profile-dropdown-menu-active' : ''
                                        }`}>
                                        <div className="dd_right">
                                            <ul>
                                                <li>
                                                    <button
                                                        onClick={goToMyProfile}
                                                        className="btn-without-background profile-dropdown-fonts">
                                                        My Profile
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        onClick={handleLogout}
                                                        className="btn-without-background profile-dropdown-fonts">
                                                        Log Out
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className="rewards-cards-container">
                    {rewardsLoading && <p className="loading-text">Loading...</p>}
                    {!rewardsLoading &&
                        pendingRewards?.length === 0 &&
                        unAcceptedRewards?.length === 0 &&
                        acceptedRewards?.length === 0 && (
                            <p className="loading-text">No Rewards.</p>
                        )}
                    {pendingRewards.map((_, i) => {
                        return (
                            <div key={_._id} className="reward-card pending">
                                <div className="top-content">
                                    <div className="wrapper">
                                        <img src={clockIcon} alt="Clock Icon" className="icon" />
                                        <span className="title">Pending Reward...</span>
                                    </div>
                                    <div className="icon-wrapper">
                                        <img src={infoIcon} alt="Info Icon" className="icon" />
                                    </div>
                                </div>
                                <p className="note">
                                    You have been successfully registered for <b>{_.name}</b> to
                                    receive a digital reward. Issuing process is in progress and
                                    once it is completed, your reward will appear here.
                                </p>
                            </div>
                        );
                    })}
                    {unAcceptedRewards.map((_, i) => {
                        return (
                            <div key={_.reward} className="reward-card">
                                <div className="new-reward">New Reward</div>
                                <div className="details-wrap">
                                    <img
                                        src={_.issuerImage}
                                        alt="Reward Issuer"
                                        className="issuer-logo"
                                    />

                                    <div
                                        onClick={() => onReceivedRewardClick(_.reward)}
                                        className="details">
                                        <p className="issue-date">{formatDate(_.issuanceDate)}</p>
                                        <p className="title">
                                            {_.issuerName + ' sent you a reward'}
                                        </p>
                                        <p className="desc">{_.title}</p>
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button
                                        onClick={() => declineRewardFunc(_)}
                                        className="button decline-button">
                                        Decline Reward
                                    </button>
                                    <button
                                        onClick={() => acceptRewardFunc(_)}
                                        className="button accept-button">
                                        Accept Reward
                                    </button>
                                </div>
                            </div>
                        );
                    })}

                    {acceptedRewards.map((_, i) => {
                        return (
                            <div
                                onClick={() => onClickReward(_.reward)}
                                key={_.reward}
                                className="card">
                                <div className="content">
                                    <div className="left">
                                        <img
                                            src={_?.issuerImage}
                                            alt="Organization Logo"
                                            className="org-logo"
                                        />

                                        <div className="event-details">
                                            <p className="event-title">
                                                <b>
                                                    {_?.rewardActivity?.eventName
                                                        ? _.rewardActivity.eventName
                                                        : _?.title}
                                                </b>
                                            </p>
                                            {_?.rewardActivity?.eventName ? (
                                                <p className="activity-title">{_.title}</p>
                                            ) : null}

                                            {(_?.rewardActivity?.otherInfo ||
                                                _?.rewardActivity?.otherInfoTag) && (
                                                <div className="other-info">
                                                    <p className="other-info-title">
                                                        {_?.rewardActivity?.otherInfo}
                                                    </p>
                                                    <p className="other-info-data">
                                                        {_?.rewardActivity?.otherInfoTag}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="right">
                                        <img
                                            src={_?.rewardActivity?.eventLogo || educationLogo}
                                            alt="Event Logo"
                                            className="event-logo"
                                        />
                                    </div>
                                </div>
                                <div className="footer">
                                    <p className="issued-by">
                                        Issued By{' '}
                                        <b>
                                            {_?.rewardActivity?.issuedBy
                                                ? _.rewardActivity.issuedBy
                                                : _.issuerName}
                                        </b>
                                    </p>
                                    <p className="issued-date">{formatDate(_.issuanceDate)}</p>
                                </div>
                            </div>
                        );
                    })}
                    {/* <div
                        onClick={() => onClickReward(_.reward)}
                        key={_.reward}
                        className="reward-card">
                        <div className="details-wrap">
                            <img
                                src={_.issuerImage}
                                alt="Reward Issuer"
                                className="issuer-logo"
                            />

                            <div className="details">
                                <p className="issue-date">{formatDate(_.issuanceDate)}</p>
                                <p className="title">
                                    {_.issuerName + ' sent you a reward'}
                                </p>
                                <p className="desc">{_.title}</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </React.Fragment>
    );
}
