import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { useAppSelector } from './app/hooks';
import { VIEWS } from './utils';
import { _isIdentityCreated } from './redux/identitySlice';
import { _vc } from './redux/signerSlice';
import { _vp } from './redux/presenterSlice';
import { _isVerified } from './redux/verifierSlice';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './Style/App.scss';
import './Style/Responsive.scss';

import Onbording from './Views/1.0 Onbording';
import Signin from './Views/1.1 Signin';
import Signup from './Views/1.2 Signup';
import Home from './Views/2.0 Home';
import NotFound from './Views/NotFound';
import BottomNavigation from './components/BottomNavigation';
import ReceivedRewardPreview from './Views/2.5.1 ReceivedRewardPreview';
import MyRewardPreview from './Views/2.6.1 MyRewardPreview';
import ReceivedRewardPreviewTrophyCase from './Views/2.7.1 ReceivedRewardPreviewTrophyCase';
import MyRewardCredentialPreview from './Views/3.1 MyRewardCredentialPreview';
import MyRewardPresentationPreview from './Views/3.3 MyRewardPresentationPreview';

(window as any).Buffer = (window as any).Buffer || require('buffer').Buffer;

const MyProfile = React.lazy(() => import('./Views/2.1 MyProfile'));
const MyContacts = React.lazy(() => import('./Views/2.2 MyContacts'));
const ContactsProfile = React.lazy(() => import('./Views/2.2.1 ContactsProfile'));
const GlobalContactProfile = React.lazy(() => import('./Views/2.2.3 GlobalContactProfile'));
const ReceivedRequests = React.lazy(() => import('./Views/2.4 ReceivedRequests'));
const PrepareReward = React.lazy(() => import('./Views/2.4.1 PrepareReward'));
const ReceivedRewards = React.lazy(() => import('./Views/2.5 ReceivedRewards'));

const RequestReward = React.lazy(() => import('./Views/2.5.2 RequestReward'));
const MyRewards = React.lazy(() => import('./Views/2.6 MyRewards'));
const MyRewardsWithoutNavbar = React.lazy(() => import('./Views/2.6.0 MyRewards'));
const IssuedRewardPreview = React.lazy(() => import('./Views/2.6.3 IssuedRewardPreview'));
const TrophyCase = React.lazy(() => import('./Views/2.7 TrophyCase'));

const ReceivedRewardPreviewTrophyCasePublic = React.lazy(
    () => import('./Views/2.7.3 ReceivedRewardPreviewPublicTrophyCase')
);
const TrophyCasePublicView = React.lazy(() => import('./Views/2.7.2 TrophyCasePublicView'));
const Verifier = React.lazy(() => import('./Views/2.8.1 VerifiedReward'));
const InviteCollegues = React.lazy(() => import('./Views/2.9 InviteCollegues'));
const SignedX = React.lazy(() => import('./Views/SignedX'));
const ViewMyId = React.lazy(() => import('./Views/2.1.1 ViewMyId'));
const Presenter = React.lazy(() => import('./Views/Presenter'));
const SeeMore = React.lazy(() => import('./Views/2.8.3 VerificationDetailsVC'));
const VerifyDomain = React.lazy(() => import('./Views/5.0 ClaimIDByDomain'));
const VerifyReward = React.lazy(() => import('./Views/VerifyReward'));
const GeneratedRewardPreview = React.lazy(() => import('./Views/2.4.1.2 GeneratedRewardPreview'));
const Notifications = React.lazy(() => import('./Views/2.3 Notifications'));
const BadgeTemplates = React.lazy(() => import('./Views/2.4.1.1 BadgeTemplates'));
const RewardCertificateView = React.lazy(() => import('./Views/RewardCertificateView'));
const MyRewardCredentials = React.lazy(() => import('./Views/3.0 MyRewardCredentials'));
const NewReward = React.lazy(() => import('./Views/3.2 NewReward'));
const LinkedInCallback = React.lazy(() => import('./Views/Callbacks/LinkedIn'));
const DidSiopCallback = React.lazy(() => import('./Views/Callbacks/DidSiop'));

// import MyProfile from './Views/2.1 MyProfile';
// import MyContacts from './Views/2.2 MyContacts';
// import ContactsProfile from './Views/2.2.1 ContactsProfile';
// import GlobalContactProfile from './Views/2.2.3 GlobalContactProfile';
// import ReceivedRequests from './Views/2.4 ReceivedRequests';
// import PrepareReward from './Views/2.4.1 PrepareReward';
// import ReceivedRewards from './Views/2.5 ReceivedRewards';

// import RequestReward from './Views/2.5.2 RequestReward';
// import MyRewards from './Views/2.6 MyRewards';
// import MyRewardsWithoutNavbar from './Views/2.6.0 MyRewards';
// import MyRewardPreview from './Views/2.6.1 MyRewardPreview';
// import IssuedRewardPreview from './Views/2.6.3 IssuedRewardPreview';
// import TrophyCase from './Views/2.7 TrophyCase';

// import ReceivedRewardPreviewTrophyCasePublic from './Views/2.7.3 ReceivedRewardPreviewPublicTrophyCase';
// import TrophyCasePublicView from './Views/2.7.2 TrophyCasePublicView';
// import Verifier from './Views/2.8.1 VerifiedReward';
// import InviteCollegues from './Views/2.9 InviteCollegues';
// import SignedX from './Views/SignedX';
// import ViewMyId from './Views/2.1.1 ViewMyId';
// import Presenter from './Views/Presenter';
// import SeeMore from './Views/2.8.3 VerificationDetailsVC';
// import VerifyDomain from './Views/5.0 ClaimIDByDomain';
// import VerifyReward from './Views/VerifyReward';
// import GeneratedRewardPreview from './Views/2.4.1.2 GeneratedRewardPreview';
// import Notifications from './Views/2.3 Notifications';
// import BadgeTemplates from './Views/2.4.1.1 BadgeTemplates';
// import RewardCertificateView from './Views/RewardCertificateView';
// import MyRewardCredentials from './Views/3.0 MyRewardCredentials';
// import NewReward from './Views/3.2 NewReward';
// import LinkedInCallback from './Views/Callbacks/LinkedIn';
// import DidSiopCallback from './Views/Callbacks/DidSiop';

export default function App() {
    const isIdentityCreated = useAppSelector(_isIdentityCreated);

    return (
        <Suspense fallback={null}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY as string}>
                <Router>
                    {isIdentityCreated ? (
                        <div className="containers-wrap">
                            <Switch>
                                <Route exact path={VIEWS.ONBORDING}>
                                    <Redirect to={VIEWS.HOME} />
                                </Route>

                                <Route path={VIEWS.HOME}>
                                    <Home />
                                </Route>

                                <Route path={VIEWS.PROFILE}>
                                    <MyProfile />
                                </Route>

                                {/* <Route path={VIEWS.NOTIFICATIONS}>
                                <Notifications />
                            </Route> */}

                                <Route path={VIEWS.INVITE}>
                                    <InviteCollegues />
                                </Route>

                                <Route path={VIEWS.MYCONTACT}>
                                    <MyContacts />
                                </Route>

                                <Route path={VIEWS.CONTACTPROFILE}>
                                    <ContactsProfile />
                                </Route>

                                <Route path={VIEWS.MYREWARDPREVIEW}>
                                    <MyRewardPreview />
                                </Route>

                                <Route path={VIEWS.MYREWARDCREDENTIALS}>
                                    <MyRewardCredentials />
                                </Route>

                                <Route path={VIEWS.MYREWARDPRESENTATIONPREVIEW}>
                                    <MyRewardPresentationPreview />
                                </Route>

                                <Route path={VIEWS.MYREWARDPCREDENTIALREVIEW}>
                                    <MyRewardCredentialPreview />
                                </Route>

                                <Route path={VIEWS.ISSUEDREWARDPREVIEW}>
                                    <IssuedRewardPreview />
                                </Route>

                                <Route path={VIEWS.TROPHYVIEW}>
                                    <ReceivedRewardPreviewTrophyCase />
                                </Route>

                                <Route path={VIEWS.TROPHYCASE}>
                                    <TrophyCase />
                                </Route>

                                <Route path={VIEWS.RECEIVEDREQUESTS}>
                                    <ReceivedRequests />
                                </Route>

                                <Route path={VIEWS.RECEIVEDREWARDS}>
                                    <ReceivedRewards />
                                </Route>

                                <Route path={VIEWS.REQUESTREWARD}>
                                    <RequestReward />
                                </Route>

                                <Route path={VIEWS.RECEIVEDREWARDPREVIEW}>
                                    <ReceivedRewardPreview />
                                </Route>

                                <Route path={VIEWS.ISSUE}>
                                    <PrepareReward />
                                </Route>

                                <Route path={VIEWS.BADGES}>
                                    <BadgeTemplates />
                                </Route>

                                <Route path={VIEWS.GENREWARDPREVIEW}>
                                    <GeneratedRewardPreview />
                                </Route>

                                <Route path={VIEWS.ACCEPT}>
                                    <Presenter />
                                </Route>

                                <Route path={VIEWS.SIGNEDVC}>
                                    <SignedX type="Credential" />
                                </Route>

                                <Route path={VIEWS.SIGNEDVP}>
                                    <SignedX type="Presentation" />
                                </Route>

                                <Route path={VIEWS.IDENTITY}>
                                    <ViewMyId />
                                </Route>

                                <Route path={VIEWS.VERIFYDOMAIN}>
                                    <VerifyDomain />
                                </Route>

                                <Route path={VIEWS.VERIFYREWARD}>
                                    <VerifyReward />
                                </Route>

                                <Route path={VIEWS.REWARDCERTIFICATE}>
                                    <RewardCertificateView />
                                </Route>

                                <Route path={VIEWS.NEWREWARD}>
                                    <NewReward />
                                </Route>

                                <Route path={`${VIEWS.VERIFIER}/:did/:vpId`}>
                                    <Verifier />
                                </Route>

                                <Route path={VIEWS.GLOBALCONTACTPROFILE}>
                                    <GlobalContactProfile />
                                </Route>

                                <Route path={`/:did${VIEWS.MYREWARDS}`}>
                                    <MyRewards />
                                </Route>

                                <Route path={VIEWS.TROPHYCASEPUBLIC}>
                                    <TrophyCasePublicView />
                                </Route>

                                <Route path={VIEWS.TROPHYVIEWPUBLIC}>
                                    <ReceivedRewardPreviewTrophyCasePublic />
                                </Route>

                                <Route path={VIEWS.MORE}>
                                    <SeeMore />
                                </Route>

                                <Route path="*">
                                    <NotFound />
                                </Route>
                            </Switch>

                            <BottomNavigation />
                        </div>
                    ) : (
                        <div className="containers-wrap">
                            <Switch>
                                <Route path={VIEWS.HOME}>
                                    <Redirect to={VIEWS.ONBORDING} />
                                </Route>

                                <Route exact path={VIEWS.ONBORDING}>
                                    <Redirect to={VIEWS.SIGNIN} />
                                </Route>

                                <Route path={`${VIEWS.SIGNIN}/:org?/:invitationId?`}>
                                    <Signin />
                                </Route>

                                <Route path={`${VIEWS.SIGNUP}/:org?/:invitationId?`}>
                                    <Signup />
                                </Route>

                                <Route path={VIEWS.ISSUE}>
                                    <Redirect to={VIEWS.ONBORDING} />
                                </Route>

                                <Route path={VIEWS.BADGES}>
                                    <Redirect to={VIEWS.ONBORDING} />
                                </Route>

                                <Route path={VIEWS.ACCEPT}>
                                    <Redirect to={VIEWS.ONBORDING} />
                                </Route>

                                <Route path={VIEWS.SIGNEDVC}>
                                    <Redirect to={VIEWS.ISSUE} />
                                </Route>

                                <Route path={VIEWS.SIGNEDVP}>
                                    <Redirect to={VIEWS.ACCEPT} />
                                </Route>

                                <Route path={`${VIEWS.VERIFIER}/:did/:vpId`}>
                                    <Verifier />
                                </Route>

                                <Route path={VIEWS.GLOBALCONTACTPROFILE}>
                                    <GlobalContactProfile />
                                </Route>

                                <Route path={`/:did${VIEWS.MYREWARDS}`}>
                                    <MyRewardsWithoutNavbar />
                                </Route>

                                <Route path={VIEWS.TROPHYCASEPUBLIC}>
                                    <TrophyCasePublicView />
                                </Route>

                                <Route path={VIEWS.TROPHYVIEWPUBLIC}>
                                    <ReceivedRewardPreviewTrophyCasePublic />
                                </Route>

                                <Route path={VIEWS.MORE}>
                                    <SeeMore />
                                </Route>

                                <Route
                                    path={`${VIEWS.LINKEDIN_CALLBACK}/:type/:org?/:invitationId?`}>
                                    <LinkedInCallback />
                                </Route>

                                <Route
                                    path={`${VIEWS.DIDSIOP_CALLBACK}/:type/:org?/:invitationId?`}>
                                    <DidSiopCallback />
                                </Route>

                                <Route path="*">
                                    <NotFound />
                                </Route>
                            </Switch>
                        </div>
                    )}
                </Router>
            </GoogleOAuthProvider>
        </Suspense>
    );
}
