import { Checkbox, FormControlLabel } from '@material-ui/core';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode.react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AddToExtensionButton from '../components/AddToExtensionButton';
import Alert from '../components/Alert';
import Button from '../components/Button';
import { shareOnFacebookHelper } from '../utils';
import Badge from './element/Badge';
import Certificate from './element/Certificate';
import Model from './element/Model';
import Other from './element/Other';
import Transcript from './element/Transcript';

const elements: any = {
    BADGE: Badge,
    CERTIFICATE: Certificate,
    TRANSCRIPT: Transcript,
    OTHER: Other,
    MODEL: Model
};

interface Props {
    index?: number;
    type?: any;
    vc?: any;
    element?: any;
    allowHide?: boolean;
    hide?: boolean;
    viewed?: boolean;
    hideDownloadButtons?: boolean;
    setHide?: any;
}

export default function Element({
    index = 0,
    type,
    vc = {},
    element = {},
    allowHide = false,
    hide = false,
    viewed = false,
    hideDownloadButtons = false,
    setHide
}: Props) {
    const [alertOpen, setAlertOpen] = React.useState({ open: false, title: '', content: null });

    const Render = elements[type];

    const history = useHistory();
    const userToken = sessionStorage.getItem('userToken');
    const decodedUserToken: any = jwt.decode(userToken as string);
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(history.location.search).get('id');

    function onSaveToFile() {
        if (element) {
            const pdf = new jsPDF({
                unit: 'px'
            });

            const imgProps = pdf.getImageProperties(element.visualPresentation);
            pdf.internal.pageSize.width = imgProps.width;
            pdf.internal.pageSize.height = imgProps.height;

            pdf.addImage(
                element.visualPresentation as string,
                'png',
                0,
                0,
                imgProps.width,
                imgProps.height
            );
            pdf.save(`${element.title}-${element.type[0]}.pdf`);
        }
    }

    React.useEffect(() => {
        const element_id = element?.id?.split('/')[5];
        const viewed = window.sessionStorage.getItem(`${element_id}-viewed`);

        if (viewed !== 'true') {
            window.sessionStorage.setItem(`${element_id}-viewed`, 'true');
            onUpdateViewCount();
        }
    }, [element]);

    const onUpdateViewCount = async () => {
        const element_id = element?.id?.split('/')[5];

        await axios.patch(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/updateElementViewCount/${element_id}`,
            {
                appType: 'wallet-app'
            }
        );
    };

    const onHideElement = async () => {
        const state = !hide;
        setHide(state);

        const result = await axios.patch(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/updateElement/${id}`,
            {
                index: index,
                state: state
            }
        );
    };

    const getExtensionDataFormat = (json: any) => {
        return btoa(JSON.stringify(json));
    };

    const onGenerateQR = () => {
        setAlertOpen({
            open: true,
            title: 'Scan QR Code',
            content: (
                <div className="elements-qr-code">
                    <QRCode value={shareOnFacebookHelper(uid, id)} size={200} renderAs="svg" />
                </div>
            )
        });
    };

    if (!Render) return null;

    return (
        <div className="insideComponentsWrap">
            <Render {...element} viewed={viewed} />

            {!hideDownloadButtons && (
                <>
                    {type !== 'MODEL' && (
                        <div className="extraMargin-iR-Btn">
                            <Button onClick={onSaveToFile} name="Save to a File" />
                        </div>
                    )}

                    <div className="extraMargin-iR-Btn">
                        <AddToExtensionButton
                            data={getExtensionDataFormat(vc)}
                            className="btn-green"
                        />
                    </div>

                    <div className="extraMargin-iR-Btn">
                        <Button onClick={onGenerateQR} name="Generate QR Code" />
                    </div>
                </>
            )}

            {allowHide && (
                <div className="elements-hide-checkbox checkbox-wrap">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hide}
                                className="greenCheckBox"
                                onChange={() => onHideElement()}
                            />
                        }
                        label={<span className="greenTxt">Hide this reward</span>}
                    />
                    <span className="note">
                        ( Conceal the reward element to prevent its display within the viewing
                        application. )
                    </span>
                </div>
            )}

            <div className="extra-mt2"></div>

            {element?.attributesValues?.length > 0 && (
                <div className="ReceivedRewardPreview-wrap">
                    <div className="issuerDetails">
                        <p className="sectionTitle">
                            <span>Additional Details</span>
                        </p>
                        {element?.attributesValues?.map((item: any, index: number) => (
                            <div className="addSectionAttributePreview" key={index.toString()}>
                                <div className="sectionAttributePreview">{item?.name}</div>
                                <div className="sectionValue">{item?.value}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </div>
    );
}
