import React from 'react';
import Alert from '../Alert';
import { VIEWS } from '../../utils';
import { useGoogleLogin } from '@react-oauth/google';
import { useAppDispatch } from '../../app/hooks';
import crypto, { createHash } from 'crypto';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import {
    setHolderDID,
    setHolderPrivateKey,
    setImage,
    setIsIdentityCreated,
    setIssuerDID,
    setIssuerPrivateKey,
    setMnemonic,
    setName,
    _mnemonic,
    setEnrolledActivity
} from '../../redux/identitySlice';
import Wallet, { Types } from 'did-hd-wallet';
import Spinner from '../Spinner';
import GoogleIco from '../../Assets/logos/google.svg';

interface Props {
    type?: string;
    children?: any;
    onClick?: any;
    org?: string;
    invitationId?: string;
}

export default function GoogleLoginButton({ type = 'signin', children, org, invitationId }: Props) {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const LoginBtnRef = React.useRef<HTMLButtonElement>(null);
    const [alertOpen, setAlertOpen] = React.useState({ open: false, title: '', content: '' });
    const [isLoading, setIsLoading] = React.useState(false);

    async function onGoogleSuccess(data) {
        setIsLoading(true);

        if (type === 'signin') {
            axios
                .post(`${process.env.REACT_APP_REWARD_BACKEND}user/signin/google`, {
                    code: data.access_token,
                    org,
                    invitationId
                })
                .then(async (response) => {
                    if (response.data && response.data.token) {
                        const userToken = response.data.token;
                        const activityData = response.data.data;
                        sessionStorage.setItem('userToken', userToken);
                        const decodedUser: any = jwt.decode(userToken);
                        const encryptedMnemonic = decodedUser.mnemonic;

                        const key = Buffer.from(
                            createHash('sha256').update(decodedUser?.google).digest('hex'),
                            'hex'
                        );
                        const components = encryptedMnemonic.split(':');
                        const iv_from_ciphertext = Buffer.from(components.shift(), 'hex');
                        const decipher = crypto.createDecipheriv('aes256', key, iv_from_ciphertext);
                        let decryptedMnemonic = decipher.update(
                            components.join(':'),
                            'hex',
                            'utf8'
                        );
                        decryptedMnemonic += decipher.final('utf8');

                        const wallet = new Wallet(Types.MNEMONIC, decryptedMnemonic);
                        const { privateKey: issuerPrivateKey, did: issuerDID } =
                            await wallet.getChildKeys(
                                process.env.REACT_APP_ISSUER_DERIVATION_PATH || 'm/256/256/1'
                            );
                        const { privateKey: holderPrivateKey, did: holderDID } =
                            await wallet.getChildKeys(
                                process.env.REACT_APP_HOLDER_DERIVATION_PATH || 'm/256/256/2'
                            );

                        const issuerRes = await axios.get(
                            `${process.env.REACT_APP_RESOLVER}${issuerDID}`
                        );
                        const holderRes = await axios.get(
                            `${process.env.REACT_APP_RESOLVER}${holderDID}`
                        );

                        if (issuerRes.data && holderRes.data) {
                            dispatch(setMnemonic(decryptedMnemonic));
                            dispatch(setHolderDID(holderDID));
                            dispatch(setHolderPrivateKey(holderPrivateKey as string));
                            dispatch(setIssuerDID(issuerDID));
                            dispatch(setIssuerPrivateKey(issuerPrivateKey as string));
                            dispatch(setIsIdentityCreated(true));
                            dispatch(setImage(decodedUser?.photo));
                            dispatch(setName(decodedUser?.name));
                            if (activityData?.activity)
                                dispatch(setEnrolledActivity(activityData?.activity));
                            history.push(VIEWS.HOME);
                        } else {
                            setAlertOpen({
                                open: true,
                                title: 'Error',
                                content: 'DID documents not found'
                            });
                        }
                    }
                })
                .catch((error) => {
                    //console.log(error)
                    setAlertOpen({
                        open: true,
                        title: 'Error',
                        content: 'User not found. Please login'
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (type === 'signup') {
            axios
                .post(`${process.env.REACT_APP_REWARD_BACKEND}user/signup/google`, {
                    code: data.access_token,
                    org,
                    invitationId
                })
                .then(async (response) => {
                    if (response.data && response.data.token) {
                        const userToken = response.data.token;
                        const activityData = response.data.data;
                        sessionStorage.setItem('userToken', userToken);
                        const decodedUser: any = jwt.decode(userToken);
                        const encryptedMnemonic = decodedUser.mnemonic;

                        const key = Buffer.from(
                            createHash('sha256').update(decodedUser?.google).digest('hex'),
                            'hex'
                        );
                        const components = encryptedMnemonic.split(':');
                        const iv_from_ciphertext = Buffer.from(components.shift(), 'hex');
                        const decipher = crypto.createDecipheriv('aes256', key, iv_from_ciphertext);
                        let decryptedMnemonic = decipher.update(
                            components.join(':'),
                            'hex',
                            'utf8'
                        );
                        decryptedMnemonic += decipher.final('utf8');

                        const wallet = new Wallet(Types.MNEMONIC, decryptedMnemonic);
                        const { privateKey: issuerPrivateKey, did: issuerDID } =
                            await wallet.getChildKeys(
                                process.env.REACT_APP_ISSUER_DERIVATION_PATH || 'm/256/256/1'
                            );
                        const { privateKey: holderPrivateKey, did: holderDID } =
                            await wallet.getChildKeys(
                                process.env.REACT_APP_HOLDER_DERIVATION_PATH || 'm/256/256/2'
                            );

                        const issuerRes = await axios.get(
                            `${process.env.REACT_APP_RESOLVER}${issuerDID}`
                        );
                        const holderRes = await axios.get(
                            `${process.env.REACT_APP_RESOLVER}${holderDID}`
                        );

                        if (issuerRes.data && holderRes.data) {
                            dispatch(setMnemonic(decryptedMnemonic));
                            dispatch(setHolderDID(holderDID));
                            dispatch(setHolderPrivateKey(holderPrivateKey as string));
                            dispatch(setIssuerDID(issuerDID));
                            dispatch(setIssuerPrivateKey(issuerPrivateKey as string));
                            dispatch(setIsIdentityCreated(true));
                            dispatch(setImage(decodedUser?.photo));
                            dispatch(setName(decodedUser?.name));
                            if (activityData?.activity)
                                dispatch(setEnrolledActivity(activityData?.activity));
                            history.push(VIEWS.HOME);
                        } else {
                            setAlertOpen({
                                open: true,
                                title: 'Error',
                                content: 'DID documents not found'
                            });
                        }
                    }
                })
                .catch((error) => {
                    //console.log(error.response.data.message)
                    if (error.response.data.message === 'User exists') {
                        setAlertOpen({
                            open: true,
                            title: 'Error',
                            content: 'Already signed up. Sign in instead'
                        });
                    } else {
                        setAlertOpen({
                            open: true,
                            title: 'Error',
                            content: 'Unknown error. Try again'
                        });
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => onGoogleSuccess(codeResponse)
    });

    return (
        <>
            <button ref={LoginBtnRef} onClick={() => login()} className="social-content-button">
                <img src={GoogleIco} className="icon-image" />
                <span className="text">{children}</span>
            </button>

            {isLoading && <Spinner />}

            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </>
    );
}
