import React, { ChangeEvent, useState } from 'react';

import '../Style/App.scss';
import '../Style/Responsive.scss';
import '../Style/Signin.scss';

import Logo from '../components/Logo';
import Button from '../components/Button';
import TopNavigationHeader from '../components/TopNavigationHeader';

import { VIEWS } from '../utils';
import {
    setHolderDID,
    setHolderPrivateKey,
    setImage,
    setIsIdentityCreated,
    setIssuerDID,
    setIssuerPrivateKey,
    setMnemonic,
    setName,
    _mnemonic,
    setEnrolledActivity
} from '../redux/identitySlice';
import Wallet, { Types, validateMnemonic } from 'did-hd-wallet';
import axios from 'axios';
import Alert from '../components/Alert';
import { createHash } from 'crypto';
import jwt from 'jsonwebtoken';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../components/Spinner';
import SiopLoginButton from '../components/logins/SiopLoginButton';
import { TextField } from '@mui/material';
import GoogleLoginButton from '../components/logins/GoogleLoginButton';
import FacebookLoginButton from '../components/logins/FacebookLoginButton';
import LinkedInLoginButton from '../components/logins/LinkedInLoginButton';

export default function Signin() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isValidMnemonic, setIsValidMnemonic] = useState<boolean>();
    const mnemonic = useAppSelector(_mnemonic);
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' });
    const { org, invitationId } = useParams<any>();

    const [validationTxt, setValidationTxt] = useState();

    async function handleSigningWithMnemonic() {
        if (validateMnemonic(mnemonic)) {
            setIsLoading(true);
            const uid: string = createHash('sha256').update(mnemonic).digest('hex');

            try {
                const wallet = new Wallet(Types.MNEMONIC, mnemonic);
                const { privateKey: issuerPrivateKey, did: issuerDID } = await wallet.getChildKeys(
                    process.env.REACT_APP_ISSUER_DERIVATION_PATH || 'm/256/256/1'
                );
                const { privateKey: holderPrivateKey, did: holderDID } = await wallet.getChildKeys(
                    process.env.REACT_APP_HOLDER_DERIVATION_PATH || 'm/256/256/2'
                );

                let userTokenResponse: any = await axios.post(
                    `${process.env.REACT_APP_REWARD_BACKEND}user/signin/mnemonic`,
                    { uid, org, invitationId }
                );

                if (!userTokenResponse) {
                    userTokenResponse = await axios.post(
                        `${process.env.REACT_APP_REWARD_BACKEND}user/signin/siop`,
                        { did: holderDID, data: {}, org, invitationId }
                    );
                }

                if (userTokenResponse.data) {
                    const userToken = userTokenResponse.data.token;
                    const activityData = userTokenResponse.data.data;
                    sessionStorage.setItem('userToken', userToken);
                    const decodedUser: any = jwt.decode(userToken);

                    try {
                        const issuerRes = await axios.get(
                            `${process.env.REACT_APP_RESOLVER}${issuerDID}`
                        );
                        const holderRes = await axios.get(
                            `${process.env.REACT_APP_RESOLVER}${holderDID}`
                        );

                        if (issuerRes.data && holderRes.data) {
                            dispatch(setHolderDID(holderDID));
                            dispatch(setHolderPrivateKey(holderPrivateKey as string));
                            dispatch(setIssuerDID(issuerDID));
                            dispatch(setIssuerPrivateKey(issuerPrivateKey as string));
                            dispatch(setIsIdentityCreated(true));
                            dispatch(setImage(decodedUser?.photo));
                            dispatch(setName(decodedUser?.name));

                            if (activityData?.activity)
                                dispatch(setEnrolledActivity(activityData?.activity));

                            history.push(VIEWS.HOME);
                        } else {
                            setAlertOpen({
                                open: true,
                                title: 'Error',
                                content: 'DID documents not found'
                            });
                        }
                        setIsLoading(false);
                    } catch (err: any) {
                        console.log(err);
                        setIsLoading(false);
                        setAlertOpen({
                            open: true,
                            title: 'Error',
                            content: "Couldn't resolve DID Doc. Try again"
                        });
                    }
                } else {
                    setAlertOpen({
                        open: true,
                        title: 'Error',
                        content: 'Unknown error. Try again'
                    });
                }
            } catch (err: any) {
                if (err.response.data) {
                    setAlertOpen({
                        open: true,
                        title: 'Error',
                        content: err.response.data.message || err.response.data.error
                    });
                } else {
                    setAlertOpen({
                        open: true,
                        title: 'Error',
                        content: 'Backend server error. Try again'
                    });
                }
            } finally {
                setIsLoading(false);
            }
        } else {
            setAlertOpen({ open: true, title: 'Error', content: 'Invalid secret ID' });
        }
    }

    function handleSigninWithLinkedin() {
        const redirect_uri = `${window.location.origin}${VIEWS.LINKEDIN_CALLBACK}`;
        let url = `${redirect_uri}/signin`;

        if (org) url.concat(`/${org}`);
        if (invitationId) url.concat(`/${invitationId}`);

        window.open(
            `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${url}&scope=r_liteprofile`,
            '_blank'
        );
    }

    function handleSetMenmonic(event: ChangeEvent<HTMLInputElement>) {
        const mnemonic = event.target.value?.trim();

        dispatch(setMnemonic(mnemonic));
        if (validateMnemonic(mnemonic)) {
            setIsValidMnemonic(true);
        } else {
            setIsValidMnemonic(false);
        }
    }

    const [isKeysGenerated, setIsKeysGenerated] = useState(true);
    const [isInput, setisInput] = useState(true);

    function handleBack() {
        history.push(VIEWS.ONBORDING);
    }

    function goToSignUp() {
        let url = VIEWS.SIGNUP;

        if (org) url.concat(`/${org}`);
        if (invitationId) url.concat(`/${invitationId}`);

        history.push(url);
    }

    return (
        <React.Fragment>
            <div className="signin-container container container-back">
                <TopNavigationHeader caption="" disabled={isKeysGenerated !== true} />
                <Logo />
                <div className="wrap">
                    <p className="onboard-title">Sign In to your account</p>

                    <GoogleLoginButton type="signin" org={org} invitationId={invitationId}>
                        Sign in With Google
                    </GoogleLoginButton>

                    <SiopLoginButton type="signin" org={org} invitationId={invitationId}>
                        Sign in With SIOP
                    </SiopLoginButton>

                    <div className="content-section">
                        <p className="onboarding-subtitle">Sign in with ID secret</p>

                        <form className="form-style" action="" onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                className="reward-input"
                                type="password"
                                placeholder="ID Secret"
                                onChange={handleSetMenmonic}
                            />

                            {validationTxt && (
                                <p className="error-validation-txt">{validationTxt}</p>
                            )}

                            <Button
                                id="sigin-button"
                                onClick={handleSigningWithMnemonic}
                                name="Sign In"
                                type="submit"
                                disabled={isInput !== true}
                            />
                        </form>
                    </div>

                    <a onClick={goToSignUp} className="signInLink">
                        <p className="subtitle subtitle-mg-signup">
                            Don't have an account ? <span>Sign Up</span>
                        </p>
                    </a>
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </React.Fragment>
    );
}
