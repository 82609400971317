const indexedDB = {
    init: (name, version = 1) => {
        const DB = window.indexedDB;

        if (!DB) {
            console.log('IndexedDB could not be found in this browser.');
        }

        version = new Date().getTime();
        const request = DB.open(name, version);

        request.onerror = function (event) {
            console.error('An error occurred with IndexedDB');
            console.error(event);
        };

        return request;
    },
    upgrade: (request, table, key = 'id') => {
        request.onupgradeneeded = function () {
            const db = request.result;

            if (!db.objectStoreNames.contains(table)) {
                db.createObjectStore(table, { keyPath: key });
            } else {
                // console.info(`${table} already exists`);
            }
        };
    },
    put: (request, table, data, id: string | number = 1) => {
        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction(table, 'readwrite');
            let store;

            if (!db.objectStoreNames.contains(table)) {
                store = transaction.createObjectStore(table, { keyPath: 'id' });
            } else {
                store = transaction.objectStore(table);
            }

            store.delete(id);
            store.put({ id, data });

            transaction.oncomplete = function () {
                db.close();
            };
        };
    },
    get: (request, table, id: string | number = 1): Promise<any> => {
        return new Promise((resolve) => {
            request.onsuccess = function () {
                const db = request.result;
                const transaction = db.transaction(table, 'readwrite');

                let store = transaction.objectStore(table);
                const idQuery = store.get(id);

                idQuery.onsuccess = function () {
                    resolve(idQuery.result);
                };

                transaction.oncomplete = function () {
                    db.close();
                };
            };
        });
    }
};

export default indexedDB;
