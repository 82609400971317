import React, { useEffect, useRef, useState } from 'react';
import '../Style/App.scss';
import '../Style/Responsive.scss';
import { useHistory } from 'react-router';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import Spinner from '../components/Spinner';
import { _receivingPrivateKey } from '../redux/identitySlice';
import Elements from '../elements';
import TopNavigationHeader from '../components/TopNavigationHeader';
import indexedDB from '../utils/storage';

function MyRewardPreview() {
    const history = useHistory();
    const [rewards, setRewards] = useState([]);
    const userToken = sessionStorage.getItem('userToken');
    const decodedUserToken: any = jwt.decode(userToken as string);
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(history.location.search).get('id');
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);

    useEffect(() => {
        setIsDataLoading(true);
        if (id && id != 'undefined') {
            getRewards(uid, id).then((res) => {
                //console.log(res.data);
                if (res.data.accepted_credentials?.length > 0) {
                    res.data.accepted_credentials[0].on_trophy = false;

                    res.data.trophy_case.forEach((reward: any) => {
                        if (reward._id === res.data.accepted_credentials[0]._id) {
                            res.data.accepted_credentials[0].on_trophy = true;
                            // @ts-ignore
                        }
                    });

                    setRewards(res.data.accepted_credentials);
                }

                setIsDataLoading(false);
            });
        }
        //console.log(rewards)
    }, []);

    const getRewards = async (did: string, id: string) => {
        try {
            const rewards = await axios.get(
                `${process.env.REACT_APP_REWARD_BACKEND}reward/getAcceptedRewardCredentials`,
                {
                    params: {
                        did,
                        id
                    }
                }
            );

            const request = indexedDB.init('RewardDatabase');
            indexedDB.upgrade(request, 'reward');
            indexedDB.put(request, 'reward', rewards?.data, id);

            return rewards;
        } catch (error) {
            const request = indexedDB.init('RewardDatabase');
            indexedDB.upgrade(request, 'reward');
            const result = await indexedDB.get(request, 'reward', id);

            if (result?.data) return { data: result.data };
        }
    };

    function handleBack() {
        history.goBack();
    }

    // @ts-ignore
    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader caption="Received Reward" handleBack={handleBack} />
                <Elements
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    loadingData={isDataLoading}
                    reward={rewards[0] ?? {}}
                    allowHide
                />
            </div>
            {(isLoading || isDataLoading) && <Spinner />}
        </React.Fragment>
    );
}

export default MyRewardPreview;
