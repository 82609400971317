import React, { useEffect, useRef, useState } from 'react';

import '../Style/App.scss';
import '../Style/Responsive.scss';

import TopNavigationHeader from '../components/TopNavigationHeader';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router';
import { VIEWS } from '../utils';
import Spinner from '../components/Spinner';
import Elements from '../elements';

function ReceivedRewardPreviewTrophyCase() {
    const [isKeysGenerated, setIsKeysGenerated] = useState(true);
    const history = useHistory();
    const [rewards, setRewards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const userToken = sessionStorage.getItem('userToken');
    const decodedUserToken: any = jwt.decode(userToken as string);
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(history.location.search).get('id');

    useEffect(() => {
        setIsDataLoading(true);
        if (id && id != 'undefined') {
            getRewards(uid, id).then((res) => {
                // setRewards(res.data.credentials);
                setRewards(res.data.trophy_case);
                setIsDataLoading(false);
            });
        }
    }, []);

    function handleBack() {
        history.goBack();
    }

    function handleDone() {
        //console.log('Clicked successfully.');
    }

    const getRewards = async (did: string, id: string) => {
        const rewards = await axios.get(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/viewTrophyCase`,
            {
                params: {
                    did,
                    id
                }
            }
        );
        return rewards;
    };

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Reward Preview"
                    handleBack={handleBack}
                    handleDone={handleDone}
                    disabled={isKeysGenerated !== true}
                />

                <Elements
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    loadingData={isDataLoading}
                    addToTrophy={false}
                    removeFromTrophy={true}
                    onAlertClose={VIEWS.TROPHYCASE}
                    reward={rewards[0] ?? {}}
                />
            </div>
            {(isLoading || isDataLoading) && <Spinner />}
        </React.Fragment>
    );
}

export default ReceivedRewardPreviewTrophyCase;
