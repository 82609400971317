import React, { useEffect, useRef, useState } from 'react';
import '../Style/App.scss';
import '../Style/Responsive.scss';
import { useHistory } from 'react-router';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import Spinner from '../components/Spinner';
import { _receivingPrivateKey } from '../redux/identitySlice';
import Elements from '../elements';
import TopNavigationHeader from '../components/TopNavigationHeader';

function MyRewardPresentationPreview() {
    const history = useHistory();
    const [reward, setReward]: any = useState({});
    const userToken = sessionStorage.getItem('userToken');
    const decodedUserToken: any = jwt.decode(userToken as string);
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(history.location.search).get('id');
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);

    useEffect(() => {
        setIsDataLoading(true);
        if (id && id != 'undefined') {
            getPresentation(uid, id).then((res) => {
                if (res.data) setReward(res.data);
                setIsDataLoading(false);
            });
        }
    }, [id]);

    const getPresentation = async (did: string, id: string) => {
        const data = await axios.get(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/getPresentation`,
            {
                params: {
                    did,
                    id
                }
            }
        );

        return data;
    };

    function handleBack() {
        history.goBack();
    }

    // @ts-ignore
    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader caption="Received Reward" handleBack={handleBack} />
                <Elements
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    loadingData={isDataLoading}
                    reward={reward?.format ?? {}}
                    allowHide
                />
            </div>
            {(isLoading || isDataLoading) && <Spinner />}
        </React.Fragment>
    );
}

export default MyRewardPresentationPreview;
